import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import * as signalR from "@microsoft/signalr";

import { useGoogleAuth } from "./useGoogleAuth";

import {
  listAlgorithms,
  describeAlgorithm,
  listAlgorithmInstances,
  describeAlgorithmInstance,
  createInstance,
  stopInstance,
  stopAllInstances,
  deleteInstance,
  setAuthToken,
  getTopMentions,
  describeSymbol
} from "../api/rehoboam";

interface RehoboamApiState {
  isSignedIn: boolean;

  listAlgorithms: typeof listAlgorithms;
  describeAlgorithm: typeof describeAlgorithm;
  listAlgorithmInstances: typeof listAlgorithmInstances;
  describeAlgorithmInstance: typeof describeAlgorithmInstance;
  createInstance: typeof createInstance;
  stopInstance: typeof stopInstance;
  stopAllInstances: typeof stopAllInstances;
  deleteInstance: typeof deleteInstance;
  getTopMentions: typeof getTopMentions;
  describeSymbol: typeof describeSymbol;
}

interface RehoboamApiProviderProps {
  defaults?: Partial<signalR.HubConnection>;
  children?: ReactNode;
}

const RehoboamApiContext = createContext<RehoboamApiState>({
  isSignedIn: false,

  listAlgorithms,
  describeAlgorithm,
  listAlgorithmInstances,
  describeAlgorithmInstance,
  createInstance,
  stopInstance,
  stopAllInstances,
  deleteInstance,
  getTopMentions,
  describeSymbol
});

export const useRehoboamApi = (): RehoboamApiState => {
  return useContext(RehoboamApiContext);
};

export const RehoboamApiProvider = (props: RehoboamApiProviderProps) => {
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const { googleUser } = useGoogleAuth();

  useEffect(() => {
    if (googleUser?.tokenId) {
      setAuthToken(googleUser.tokenId);
      setIsSignedIn(true);
    } else {
      setAuthToken("");
      setIsSignedIn(false);
    }
  }, [googleUser]);

  return (
    <RehoboamApiContext.Provider
      value={{
        isSignedIn,

        listAlgorithms,
        describeAlgorithm,
        listAlgorithmInstances,
        describeAlgorithmInstance,
        createInstance,
        stopInstance,
        stopAllInstances,
        deleteInstance,
        getTopMentions,
        describeSymbol
      }}
    >
      {props.children}
    </RehoboamApiContext.Provider>
  );
};
