import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Particles from "react-particles-js";

import halfmoon from "halfmoon";

import "./LandingPage.css";

const LandingPage: React.FC = () => {
  // The Landing Page only supports dark mode.
  useEffect(() => {
    if (halfmoon.getPreferredMode() !== "dark-mode") {
      halfmoon.toggleDarkMode();
    }
  });

  return (
    <div className="page-wrapper with-navbar with-navbar-fixed-bottom">
      <NavBar />
      <ParticleBackground />
      <div className="content-wrapper d-lg-flex align-items-lg-center">
        <div className="container-lg">
          <div className="row">
            <div className="col-lg-8">
              <div className="content">
                <h1 className="text-center text-lg-left" style={{ fontSize: 38 }}>
                  <strong className="font-weight-medium">The Future of Algorithmic Trading</strong>
                </h1>
                <h5>
                  Rehoboam is an algorithmic trading platform used to manage execution times, perform backtests, and
                  monitor performance.
                </h5>
                <div className="mt-20 d-flex justify-content-center justify-content-lg-start">
                  <Link to="/algorithms">
                    <button className="btn btn-primary btn-lg">Get Started</button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div>
                <br />
                <div className=" d-flex justify-content-center justify-content-lg-start">
                  <img className="img-fluid" src={require("../../img/rocket.svg")} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NavBar: React.FC = () => {
  return (
    <nav className="navbar" style={{ backgroundColor: "black" }}>
      <NavLink to="/" className="navbar-brand ml-10 ml-sm-15">
        <span className="mr-5 mr-sm-10" style={{ fontSize: 22 }}>
          <i style={{ color: "#0be881" }} className="far fa-circle" />
          {" Rehoboam"}
        </span>
      </NavLink>
      <span className="badge font-size-12 text-monospace px-5 px-sm-10">alpha</span>
    </nav>
  );
};

const ParticleBackground: React.FC = () => {
  return (
    <Particles
      style={{ width: "100%", height: "100%" }}
      params={{
        particles: {
          number: {
            value: 100,
            density: {
              enable: true,
              value_area: 4000,
            },
          },
          size: {
            value: 5,
            random: true,
          },
          move: {
            direction: "bottom",
            out_mode: "out",
            straight: true,
            speed: 3,
          },
          line_linked: {
            enable: false,
          },
        },
      }}
    />
  );
};

export default LandingPage;
