import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRehoboamApi } from "../../hooks/useRehoboamApi";
import { IAlgorithm } from "../../models";

const SideBar: React.FC = () => {
  const { listAlgorithms, isSignedIn } = useRehoboamApi();
  const [state, setState] = useState<{ algorithms: IAlgorithm[] }>({
    algorithms: [],
  });

  useEffect(() => {
    if (!isSignedIn) return;

    listAlgorithms().then((response) => {
      const statusCode = response.statusCode;
      const algorithms = statusCode === 200 ? response.data : [];
      setState((prevState) => ({ ...prevState, algorithms }));
    });
  }, [isSignedIn, listAlgorithms]);

  return (
    <div className="sidebar">
      <div className="sidebar-menu">
        <h5 className="sidebar-title">Getting Started</h5>
        <div className="sidebar-divider"></div>
        <Link to="/algorithms" className="sidebar-link sidebar-link-with-icon">
          <span className="sidebar-icon bg-primary">
            <i className="far fa-hdd"></i>
          </span>
          Algorithms
        </Link>
        <Link to="/top-mentions" className="sidebar-link sidebar-link-with-icon">
          <span className="sidebar-icon bg-success">
            <i className="far fa-file-alt"></i>
          </span>
          Top Mentions
        </Link>
        <br />
        <h5 className="sidebar-title">Algorithms</h5>
        <div className="sidebar-divider"></div>
        {state.algorithms.map((algorithm) => {
          return (
            <Link key={algorithm.id} to={"/algorithms/" + algorithm.id} className="sidebar-link">
              {algorithm.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SideBar;
