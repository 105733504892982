import React, { Component } from "react";
import Chart, { ChartPoint, ChartColor } from "chart.js";

import { IAggregation, IOrder, OrderSide } from "../models";

const red = "rgba(220, 53, 69, 1)";
const redAlpha = "rgba(220, 53, 69, .5)";
const green = "rgba(40, 167, 69, 1)";
const greenAlpha = "rgba(40, 167, 69, .5)";

interface ILineChartJsProps {
  aggregations: IAggregation[];
  orders: IOrder[];
}

class LineChartJs extends Component<ILineChartJsProps, { chart: Chart }> {
  render() {
    return <canvas id="lineChart"></canvas>;
  }

  componentDidMount() {
    this.initializeChart();
  }

  componentDidUpdate(prevProps: ILineChartJsProps) {
    if (this.props.aggregations.length !== prevProps.aggregations.length) {
      this.addAggregation(this.props.aggregations);
    }

    if (this.props.orders.length !== prevProps.orders.length) {
      this.addOrder(this.props.orders);
    }
  }

  initializeChart() {
    var ctx = document.getElementById("lineChart") as any;

    var chart = new Chart(ctx, {
      type: "line",
      data: {
        datasets: [
          {
            label: "Price",
            data: [],
            backgroundColor: "rgba(0, 123, 255, .5)",
            borderColor: "rgba(0, 123, 255, 1)",
            pointRadius: 2,
            fill: false,
            xAxisID: "time",
            yAxisID: "left",
          },
          {
            label: "Orders",
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 3,
            type: "bar",
            xAxisID: "time",
            yAxisID: "right",
          },
        ],
      },

      options: {
        animation: { duration: 0 },
        tooltips: { enabled: false },
        legend: { display: true, position: "bottom" },
        scales: {
          xAxes: [
            {
              id: "time",
              type: "time",
              time: {
                unit: "minute",
              },
            },
          ],
          yAxes: [
            {
              id: "left",
              type: "linear",
              position: "left",
              scaleLabel: { display: true, labelString: "Price" },
            },
            {
              id: "right",
              type: "linear",
              position: "right",
              ticks: { suggestedMin: 0, suggestedMax: 10 },
              scaleLabel: { display: true, labelString: "Orders" },
            },
          ],
        },
      },
    });

    this.setState({ chart });
  }

  addAggregation(agg: IAggregation[]) {
    const lastAgg = agg[agg.length - 1];

    const priceData = this.state.chart.data.datasets?.[0].data as ChartPoint[];
    // TODO: Time should come from the server.
    priceData.push({ t: Date.now(), y: lastAgg.close });

    this.state.chart.update();
  }

  addOrder(order: IOrder[]) {
    const lastOrder = order[order.length - 1];

    const orders = this.state.chart.data.datasets?.[1].data as ChartPoint[];
    const borderColors = this.state.chart.data.datasets?.[1].borderColor as ChartColor[];
    const backgroundColors = this.state.chart.data.datasets?.[1].backgroundColor as ChartColor[];

    // TODO: Time should come from the server.
    orders.push({ t: Date.now(), y: lastOrder.quantity });
    lastOrder.side === OrderSide.Buy ? borderColors.push(green) : borderColors.push(red);
    lastOrder.side === OrderSide.Buy ? backgroundColors.push(greenAlpha) : backgroundColors.push(redAlpha);

    this.state.chart.update();
  }
}

export default LineChartJs;
