import React from "react";
import { BarLoader } from "react-spinners";

const Loader: React.FC = () => {
  return (
    <>
      <div className="d-flex mt-5 justify-content-center">
        <h5>Loading...</h5>
      </div>
      <div className="d-flex mt-1 justify-content-center">
        <BarLoader color="#1890ff" width={250} />
      </div>
    </>
  );
};

export default Loader;
