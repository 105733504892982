import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import * as signalR from "@microsoft/signalr";

import { useGoogleAuth } from "./useGoogleAuth";
import { createDataConnection } from "../api/rehoboam";

interface DataConnectionState {
  dataConnection: signalR.HubConnection | undefined;
}

interface DataConnectionProviderProps {
  defaults?: Partial<signalR.HubConnection>;
  children?: ReactNode;
}

const DataConnectionContext = createContext<DataConnectionState>({
  dataConnection: undefined,
});

export const useDataConnection = (): DataConnectionState => {
  return useContext(DataConnectionContext);
};

export const DataConnectionProvider = (props: DataConnectionProviderProps) => {
  const [dataConnection, setDataConnection] = useState<signalR.HubConnection | undefined>(undefined);
  const { googleUser } = useGoogleAuth();

  useEffect(() => {
    if (googleUser?.tokenId && !dataConnection) {
      setDataConnection(createDataConnection(googleUser.tokenId));
    }
  }, [googleUser, dataConnection]);

  return (
    <DataConnectionContext.Provider
      value={{
        dataConnection,
      }}
    >
      {props.children}
    </DataConnectionContext.Provider>
  );
};
