export interface IAlgorithmInstance {
  id: string;
  state: InstanceState;

  createDateUtc: Date;
  executionStartUtc?: Date;
  executionEndUtc?: Date;
}

export enum InstanceState {
  Created = 0,
  Running = 1,
  Terminated = 2,
}
