export interface IOrder {
  symbol: string;
  quantity: number;

  side: OrderSide;
}

export enum OrderSide {
  Buy = 0,
  Sell = 1,
}
