import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Loader from "../../components/Loader";

import { useRehoboamApi } from "../../hooks/useRehoboamApi";

import { ISentiment } from "../../models";

const TopMentionsPage: React.FC = () => {
  const { getTopMentions, isSignedIn } = useRehoboamApi();
  const [state, setState] = useState<{ date: string; statusCode?: number; topMentions: ISentiment[] }>({
    date: getTodaysDate(),
    statusCode: undefined,
    topMentions: [],
  });

  useEffect(() => {
    if (!isSignedIn) return;

    getTopMentions(state.date).then((response) => {
      const statusCode = response.statusCode;
      const topMentions =
        statusCode === 200 ? response.data.filter((s: ISentiment) => s.symbol !== "TOTAL_MARKET") : [];

      setState((prevState) => ({ ...prevState, statusCode, topMentions }));
    });
  }, [isSignedIn, state.date, getTopMentions]);

  if (!state.statusCode) {
    return (
      <div className="container-fluid">
        <Header date={state.date} />
        <Loader />
      </div>
    );
  }

  if (state.statusCode !== 200) {
    return (
      <div className="container-fluid">
        <Header date={state.date} />
        <ApiError />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <Header date={state.date} />
      <TopMentionsList topMentions={state.topMentions} />
    </div>
  );
};

const TopMentionsList: React.FC<{ topMentions: ISentiment[] }> = ({ topMentions }) => {
  console.log(topMentions);
  return (
    <>
      {topMentions.map((topMention) => (
        <div key={topMention.symbol} className="card">
          <div className="clearfix">
            <div className="float-left d-inline-block">
              <h2 className="card-title">{`${topMention.symbol} - ${topMention.mentions}`}</h2>
            </div>
            <div className="float-right d-inline-block">
              <Link to={`/top-mentions/${topMention.symbol}`}>
                <button className="btn btn-primary mt-5" type="button">
                  <span className="text">{"Dashboard"}</span>
                </button>
              </Link>
            </div>
          </div>
          <div className="text mb-10">{`Sentiment: ${topMention.sentiment} `}</div>
        </div>
      ))}
    </>
  );
};

const Header: React.FC<{ date: string }> = ({ date }) => {
  return (
    <div className="content">
      <h1 className="content-title font-size-22">{`Top Mentions - ${date}`}</h1>
      <p>The top stocks people are talking about.</p>
    </div>
  );
};

const ApiError: React.FC = () => {
  return (
    <div className="card alert alert-secondary filled-dm" role="alert">
      <h4 className="alert-heading">No Top Mentions Found</h4>
      We couldn't find any top mentions. You might not be signed in.
    </div>
  );
};

const getTodaysDate = () => {
  const today = new Date();
  let dd: any = today.getDate();
  let mm: any = today.getMonth() + 1;
  let yyyy = today.getFullYear();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return `${yyyy}-${mm}-${dd}`;
};

export default TopMentionsPage;
