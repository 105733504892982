import React from "react";

import NavBar from "./NavBar";
import Footer from "./Footer";
import SideBar from "./SideBar";

import halfmoon from "halfmoon";
import { useRouteMatch } from "react-router-dom";

const Layout: React.FC = (props) => {
  // The landing and login pages completely style themselves.
  const landingMatch = useRouteMatch("/")?.isExact;
  const loginMatch = useRouteMatch("/login")?.isExact;

  if (landingMatch || loginMatch) {
    return <>{props.children}</>;
  }

  return (
    <>
      <div className="sidebar-overlay" onClick={() => halfmoon.toggleSidebar()} />

      <div className="sticky-alerts"></div>

      <NavBar />

      <SideBar />

      <div className="content-wrapper">{props.children}</div>

      <Footer />
    </>
  );
};

export default Layout;
