import React from "react";
import { BrowserRouter } from "react-router-dom";

import Router from "./components/Router";
import Layout from "./components/Layout/Layout";
import PageWrapper from "./components/Layout/PageWrapper";

import { DataConnectionProvider } from "./hooks/useDataConnection";
import { GoogleAuthProvider } from "./hooks/useGoogleAuth";
import { RehoboamApiProvider } from "./hooks/useRehoboamApi";

const App: React.FC = () => {
  return (
    <GoogleAuthProvider>
      <RehoboamApiProvider>
        <DataConnectionProvider>
          <BrowserRouter>
            <PageWrapper>
              <Layout>
                <Router />
              </Layout>
            </PageWrapper>
          </BrowserRouter>
        </DataConnectionProvider>
      </RehoboamApiProvider>
    </GoogleAuthProvider>
  );
};

export default App;
