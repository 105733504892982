import React, { Component } from "react";
import Chart from "chart.js";

import { ISentiment } from "../models";

interface ISentimentLineChartJsProps {
  sentiments: ISentiment[];
}

class SentimentLineChartJs extends Component<ISentimentLineChartJsProps, { chart: Chart }> {
  render() {
    return <canvas id="lineChart"></canvas>;
  }

  componentDidMount() {
    this.initializeChart();
  }

  initializeChart() {
    var ctx = document.getElementById("lineChart") as any;

    const mentions = this.props.sentiments.map((sentiment) => {
      return { t: sentiment.dateTimeUtc, y: sentiment.mentions };
    });

    const sentiments = this.props.sentiments.map((sentiment) => {
      return { t: sentiment.dateTimeUtc, y: sentiment.sentiment };
    });

    var chart = new Chart(ctx, {
      type: "line",
      data: {
        datasets: [
          {
            label: "Mentions",
            data: mentions,
            backgroundColor: "rgba(0, 123, 255, .5)",
            borderColor: "rgba(0, 123, 255, 1)",
            pointRadius: 3,
            fill: false,
            xAxisID: "time",
            yAxisID: "left",
          },
          {
            label: "Sentiment",
            data: sentiments,
            backgroundColor: "rgba(40, 167, 69, .5)",
            borderColor: "rgba(40, 167, 69, 1)",
            pointRadius: 3,
            fill: false,
            xAxisID: "time",
            yAxisID: "right",
          },
        ],
      },

      options: {
        animation: { duration: 0 },
        tooltips: { enabled: true },
        legend: { display: true, position: "bottom" },
        scales: {
          xAxes: [
            {
              id: "time",
              type: "time",
              time: {
                unit: "day",
              },
            },
          ],
          yAxes: [
            {
              id: "left",
              type: "linear",
              position: "left",
              ticks: { suggestedMin: 0 },
              scaleLabel: { display: true, labelString: "Mentions" },
            },
            {
              id: "right",
              type: "linear",
              position: "right",
              ticks: { suggestedMin: 0, suggestedMax: 1 },
              scaleLabel: { display: true, labelString: "Sentiment" },
            },
          ],
        },
      },
    });

    this.setState({ chart });
  }
}

export default SentimentLineChartJs;
