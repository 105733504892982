import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { describeSymbol } from "../../api/rehoboam";

import Loader from "../../components/Loader";
import SentimentLineChartJs from "../../components/SentimentLineChartJs";

import { useRehoboamApi } from "../../hooks/useRehoboamApi";

import { ISentiment } from "../../models";

interface Params {
  symbol: string;
}

const TrendsPage: React.FC = () => {
  const { getTopMentions, isSignedIn } = useRehoboamApi();
  const { symbol } = useParams<Params>();
  const [state, setState] = useState<{ statusCode?: number; sentiments: ISentiment[] }>({
    statusCode: undefined,
    sentiments: [],
  });

  useEffect(() => {
    if (!isSignedIn) return;

    describeSymbol(symbol).then((response) => {
      const statusCode = response.statusCode;
      const sentiments = statusCode === 200 ? response.data : [];

      setState((prevState) => ({ ...prevState, statusCode, sentiments }));
    });
  }, [isSignedIn, symbol, getTopMentions]);

  if (!state.statusCode) {
    return (
      <div className="container-fluid">
        <Header />
        <Loader />
      </div>
    );
  }

  if (state.statusCode !== 200) {
    return (
      <div className="container-fluid">
        <Header />
        <ApiError />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <Header />
      <SentimentGraph sentiments={state.sentiments} />
    </div>
  );
};

const SentimentGraph: React.FC<{ sentiments: ISentiment[] }> = ({ sentiments }) => {
  console.log(sentiments);
  return (
    <>
      <div className="card">
        <SentimentLineChartJs sentiments={sentiments} />
      </div>
    </>
  );
};

const Header: React.FC = () => {
  const { symbol } = useParams<Params>();

  return (
    <div className="content">
      <h1 className="content-title font-size-22">{`Trends - ${symbol}`}</h1>
      <p>Senitment and Mention data for the past 2 weeks.</p>
    </div>
  );
};

const ApiError: React.FC = () => {
  return (
    <div className="card alert alert-secondary filled-dm" role="alert">
      <h4 className="alert-heading">No Found</h4>
      We couldn't find any trending data. You might not be signed in.
    </div>
  );
};

export default TrendsPage;
