import React from "react";

import halfmoon from "halfmoon";

import { NavLink, useHistory } from "react-router-dom";
import { useGoogleAuth } from "../../hooks/useGoogleAuth";

const NavBar: React.FC = () => {
  return (
    <nav className="navbar">
      <div className="navbar-content">
        <button
          id="toggle-sidebar-btn"
          className="btn btn-action"
          type="button"
          onClick={() => halfmoon.toggleSidebar()}
        >
          <i className="fa fa-bars"></i>
        </button>
      </div>
      <NavLink to="/" className="navbar-brand ml-10 ml-sm-15">
        <span className="mr-5 mr-sm-10" style={{ fontSize: 22 }}>
          <i style={{ color: "#0be881" }} className="far fa-circle" />
          {" Rehoboam"}
        </span>
      </NavLink>
      <span className="badge font-size-12 text-monospace px-5 px-sm-10">alpha</span>
      <ul className="navbar-nav hidden-sm-and-down">
        <li className="nav-item">
          <NavLink to="/algorithms" className="nav-link">
            Algorithms
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/top-mentions" className="nav-link">
            Top Mentions
          </NavLink>
        </li>
      </ul>

      <div className="navbar-content ml-auto">
        <button className="btn btn-action mr-5" type="button" onClick={() => halfmoon.toggleDarkMode()}>
          <i className="far fa-moon"></i>
        </button>

        <NavBarSignIn />

        <div className="dropdown with-arrow hidden-md-and-up">
          <button className="btn navbar-menu-btn" data-toggle="dropdown" type="button" id="navbar-dropdown-toggle-btn">
            <span className="text">{"Menu "}</span>
            <i className="fa fa-angle-down"></i>
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <NavLink to="/algorithms" className="dropdown-item">
              Algorithms
            </NavLink>
            <NavLink to="/text-analysis" className="dropdown-item">
              Textual Analysis
            </NavLink>
            <div className="dropdown-divider"></div>
            <MenuSignIn />
          </div>
        </div>
      </div>
    </nav>
  );
};

const NavBarSignIn: React.FC = () => {
  const { signIn, signOut, googleUser, isSignedIn, isInitialized } = useGoogleAuth();
  const history = useHistory();

  if (isSignedIn) {
    console.log(googleUser?.tokenId);
    return (
      <>
        <button
          className="btn btn-danger hidden-sm-and-down mr-5"
          type="button"
          onClick={() => signOut().then(() => history.push("/"))}
        >
          <span className="text">{"Sign Out"}</span>
        </button>
        <img
          src={googleUser?.profileObj?.imageUrl}
          alt=""
          width="40"
          height="40"
          className="img-fluid rounded-circle hidden-sm-and-down"
        />
      </>
    );
  }

  if (isInitialized) {
    return (
      <button className="btn btn-primary hidden-sm-and-down" type="button" onClick={() => signIn()}>
        <span className="text">{"Sign In"}</span>
      </button>
    );
  }

  return <></>;
};

const MenuSignIn: React.FC = () => {
  const { signIn, signOut, googleUser, isSignedIn, isInitialized } = useGoogleAuth();
  const history = useHistory();

  if (isSignedIn) {
    console.log(googleUser?.tokenId);
    return (
      <div className="dropdown-content">
        <button className="btn btn-danger mr-5" type="button" onClick={() => signOut().then(() => history.push("/"))}>
          <span className="text">{"Sign Out"}</span>
        </button>
        <img
          src={googleUser?.profileObj?.imageUrl}
          alt=""
          width="32"
          height="32"
          className="img-fluid rounded-circle align-bottom"
        />
      </div>
    );
  }

  if (isInitialized) {
    return (
      <div className="dropdown-content">
        <button className="btn btn-primary" type="button" onClick={() => signIn()}>
          <span className="text">{"Sign In"}</span>
        </button>
      </div>
    );
  }

  return <></>;
};

export default NavBar;
