import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";

import LineChartJs from "../../components/LineChartJs";
import Loader from "../../components/Loader";

import { useDataConnection } from "../../hooks/useDataConnection";
import { useRehoboamApi } from "../../hooks/useRehoboamApi";

import {
  IAccountValue,
  IAggregation,
  IAlgorithm,
  IAlgorithmInstance,
  InstanceState,
  IOrder,
  OrderSide,
} from "../../models";

interface State {
  algorithm?: IAlgorithm;
  algorithmInstances: IAlgorithmInstance[];
  selectedInstance?: IAlgorithmInstance;
}

interface Params {
  algorithmId: string;
}

const AlgorithmPage: React.FC = () => {
  const { describeAlgorithm, listAlgorithmInstances, isSignedIn } = useRehoboamApi();
  const { algorithmId } = useParams<Params>();

  const [state, setState] = useState<State>({
    algorithm: undefined,
    algorithmInstances: [],
    selectedInstance: undefined,
  });

  const updateInstances = useCallback(
    (algorithmId: string) => {
      listAlgorithmInstances(algorithmId).then((response) => {
        if (response.statusCode === 200) {
          setState((prevState) => ({ ...prevState, algorithmInstances: response.data }));
        }
      });
    },
    [listAlgorithmInstances]
  );

  const updateSelectedInstance = useCallback((instance: IAlgorithmInstance) => {
    setState((prevState) => {
      // Toggle existing selection.
      const selectedInstance = prevState.selectedInstance?.id === instance.id ? undefined : instance;
      return { ...prevState, selectedInstance };
    });
  }, []);

  useEffect(() => {
    if (!isSignedIn) {
      return;
    }

    describeAlgorithm(algorithmId).then((response) => {
      if (response.statusCode === 200) {
        setState((prevState) => ({ ...prevState, algorithm: response.data }));
      }
    });

    updateInstances(algorithmId);
  }, [algorithmId, isSignedIn, describeAlgorithm, updateInstances]);

  if (!state.algorithm) {
    return <Loader />;
  }

  return (
    <div className="container-fluid">
      <Header algorithm={state.algorithm} />
      <div className="row row-eq-spacing">
        <ActionCard updateInstances={updateInstances} />
        <AccountValueCard />
        <div className="v-spacer d-xl-none" />
        <ProfitCard />
        <OrdersCard />
      </div>
      <SignalRChart />
      <Executions
        instances={state.algorithmInstances}
        selectedInstance={state.selectedInstance}
        updateInstances={updateInstances}
        updateSelectedInstance={updateSelectedInstance}
      />
    </div>
  );
};

const ActionCard: React.FC<{ updateInstances: (algorithmId: string) => void }> = ({ updateInstances }) => {
  const { createInstance, stopAllInstances } = useRehoboamApi();
  const { algorithmId } = useParams<Params>();

  return (
    <div className="col-6 col-xl-3 d-flex">
      <div className="card p-15 flex-fill">
        <h2 className="card-title">Actions</h2>
        <button
          type="button"
          className="btn btn-success mr-5"
          onClick={() => createInstance(algorithmId).then(() => updateInstances(algorithmId))}
        >
          Start
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => stopAllInstances(algorithmId).then(() => updateInstances(algorithmId))}
        >
          Stop
        </button>
      </div>
    </div>
  );
};

const AccountValueCard: React.FC = () => {
  const { dataConnection } = useDataConnection();
  const [accountValue, setAccountValue] = useState<IAccountValue>();

  useEffect(() => {
    dataConnection?.on("NewAccountValue", (accountValue: IAccountValue) => {
      setAccountValue(accountValue);
    });
  }, [dataConnection]);

  return (
    <div className="col-6 col-xl-3 d-flex">
      <div className="card p-15 flex-fill">
        <h2 className="card-title">Account Value</h2>
        {accountValue?.totalEquity ?? "..."}
      </div>
    </div>
  );
};

const ProfitCard: React.FC = () => {
  const { dataConnection } = useDataConnection();
  const [startingValue, setStartingValue] = useState<IAccountValue>();
  const [accountValue, setAccountValue] = useState<IAccountValue>();

  useEffect(() => {
    dataConnection?.on("NewAccountValue", (accountValue: IAccountValue) => {
      setAccountValue(accountValue);
    });
  }, [dataConnection]);

  var profitPercent;
  if (!accountValue) {
    profitPercent = "...";
  } else if (!startingValue) {
    setStartingValue(accountValue);
    profitPercent = "0.0000%";
  } else {
    const diff = accountValue.totalEquity - startingValue.totalEquity;
    profitPercent = ((diff / startingValue.totalEquity) * 100).toFixed(4) + "%";
  }

  return (
    <div className="col-6 col-xl-3 d-flex">
      <div className="card p-15 flex-fill">
        <h2 className="card-title">Profit</h2>
        {profitPercent}
      </div>
    </div>
  );
};

const OrdersCard: React.FC = () => {
  const { dataConnection } = useDataConnection();
  const [buys, setBuys] = useState(0);
  const [sells, setSells] = useState(0);

  useEffect(() => {
    dataConnection?.on("NewOrderPlacement", (order: IOrder) => {
      order.side === OrderSide.Buy
        ? setBuys((buys) => buys + order.quantity)
        : setSells((sells) => sells + order.quantity);
    });
  }, [dataConnection]);

  return (
    <div className="col-6 col-xl-3 d-flex">
      <div className="card p-15 flex-fill">
        <h2 className="card-title">Orders</h2>
        {(buys || sells) !== 0 ? "Buys: " + buys + " Sells: " + sells : "..."}
      </div>
    </div>
  );
};

const Executions: React.FC<{
  instances: IAlgorithmInstance[];
  selectedInstance?: IAlgorithmInstance;
  updateInstances: (algorithmId: string) => void;
  updateSelectedInstance: (instance: IAlgorithmInstance) => void;
}> = ({ instances, selectedInstance, updateInstances, updateSelectedInstance }) => {
  const { stopInstance, deleteInstance } = useRehoboamApi();
  const { algorithmId } = useParams<Params>();

  return (
    <>
      <div className="content">
        <h1 className="content-title font-size-22">Executions</h1>
      </div>
      {instances.map((instance) => {
        const executionStartUtc = instance.executionStartUtc?.toString().substring(0, 19) || "Not Started";
        const executionEndUtc = instance.executionEndUtc?.toString().substring(0, 19) || "";

        return (
          <div className="card" key={instance.id} onClick={() => updateSelectedInstance(instance)}>
            <div className="clearfix">
              <div className="float-left d-inline-block">
                <h2 className="card-title mb-0">{instance.id}</h2>
              </div>
              <div className="float-right d-inline-block">
                <span
                  className={"badge " + (instance.state === InstanceState.Running ? "badge-success" : "badge-danger")}
                >
                  {InstanceState[instance.state]}
                </span>
              </div>
            </div>

            <p className="d-inline-block mr-10">
              Started <span className="badge">{executionStartUtc}</span>
            </p>
            <p className="d-inline-block">
              Ended <span className="badge">{executionEndUtc}</span>
            </p>
            <br />
            <button
              type="button"
              disabled={instance.state === InstanceState.Terminated}
              className={"btn btn-secondary mr-10"}
              onClick={() => stopInstance(algorithmId, instance.id).then(() => updateInstances(algorithmId))}
            >
              Stop
            </button>
            <button
              type="button"
              disabled={instance.state !== InstanceState.Terminated}
              className={"btn btn-danger mr-10"}
              onClick={() => deleteInstance(algorithmId, instance.id).then(() => updateInstances(algorithmId))}
            >
              Delete
            </button>
          </div>
        );
      })}
    </>
  );
};

const SignalRChart: React.FC = () => {
  const { dataConnection } = useDataConnection();
  const [aggregations, setAggregations] = useState<IAggregation[]>([]);
  const [orders, setOrders] = useState<IOrder[]>([]);

  useEffect(() => {
    dataConnection?.on("NewAggregation", (agg: IAggregation) => {
      setAggregations((aggregations) => [...aggregations, agg]);
    });

    dataConnection?.on("NewOrderPlacement", (order: IOrder) => {
      setOrders((orders) => [...orders, order]);
    });
  }, [dataConnection]);

  return (
    <div className="card">
      <h2 className="card-title">Chart</h2>
      <LineChartJs aggregations={aggregations} orders={orders} />
    </div>
  );
};

const Header: React.FC<{ algorithm: IAlgorithm }> = ({ algorithm }) => {
  return (
    <div className="content">
      <h1 className="content-title font-size-22">{algorithm.name}</h1>
      <p>{algorithm.description}</p>
    </div>
  );
};

export default AlgorithmPage;
