import React, { useContext } from "react";
import { useGoogleLogin, GoogleLoginHookReturnValue } from "react-use-googlelogin";

const createContext = <A extends {} | null>() => {
  const ctx = React.createContext<A | undefined>(undefined);

  const useCtx = () => {
    const contextValue = useContext(ctx);

    if (contextValue === undefined) throw new Error("useCtx must be inside a Provider with a value");

    return contextValue;
  };

  return [useCtx, ctx.Provider] as const;
};

const [useGoogleAuth, AuthProvider] = createContext<GoogleLoginHookReturnValue>();

export const GoogleAuthProvider: React.FC = ({ children }) => {
  const hookData = useGoogleLogin({
    clientId: "217785229661-1r0l3namog3sml4kt67lohg50en9o9po.apps.googleusercontent.com",
  });

  return <AuthProvider value={hookData}>{children}</AuthProvider>;
};

export { useGoogleAuth };
