import React, { useEffect, useState } from "react";
import { useDataConnection } from "../../hooks/useDataConnection";

const Footer: React.FC = () => {
  const { dataConnection } = useDataConnection();
  const [time, setTime] = useState<string>("");

  useEffect(() => {
    dataConnection?.on("NewDateTime", (dateTime) => {
      setTime(dateTime.substring(0, 19));
    });
  }, [dataConnection]);

  return (
    <nav className="navbar navbar-fixed-bottom">
      <div className="navbar-content ml-auto">
        <span className="badge mr-5 px-5 px-sm-10">{"Server Time: " + time}</span>
        <span className="text">© Copyright 2020</span>
      </div>
    </nav>
  );
};

export default Footer;
