import React, { useEffect } from "react";

import halfmoon from "halfmoon";

const PageWrapper: React.FC = (props) => {
  // All pages need to use the same page-wrapper for halfmoon js.
  useEffect(() => {
    halfmoon.onDOMContentLoaded();
  }, []);

  return (
    <div
      className="page-wrapper with-navbar with-sidebar with-navbar-fixed-bottom"
      data-sidebar-type="overlayed-sm-and-down"
    >
      {props.children}
    </div>
  );
};

export default PageWrapper;
