import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useRehoboamApi } from "../hooks/useRehoboamApi";

import AlgorithmListPage from "../pages/AlgorithmListPage";
import AlgorithmPage from "../pages/AlgorithmPage";
import LandingPage from "../pages/LandingPage";
import LoginPage from "../pages/LoginPage";
import TopMentionsPage from "../pages/TopMentionsPage";
import TrendsPage from "../pages/TrendsPage";

const UnAuthenticatedRoutes = ["/", "/login"];

const Router = () => {
  const { isSignedIn } = useRehoboamApi();
  const location = useLocation();

  if (needsAuthentication(isSignedIn, location.pathname)) {
    return <Redirect to={"/login"} />;
  }

  return (
    <Switch>
      <Route path={"/"} exact={true}>
        <LandingPage />
      </Route>
      <Route path={"/algorithms"} exact={true}>
        <AlgorithmListPage />
      </Route>
      <Route path={"/algorithms/:algorithmId"}>
        <AlgorithmPage />
      </Route>
      <Route path={"/top-mentions"} exact={true}>
        <TopMentionsPage />
      </Route>
      <Route path={"/top-mentions/:symbol"}>
        <TrendsPage />
      </Route>
      <Route path={"/login"} exact={true}>
        <LoginPage />
      </Route>
      <Redirect to={"/"} />
    </Switch>
  );
};

const needsAuthentication = (isSignedIn: boolean, pathname: string) => {
  return !isSignedIn && !UnAuthenticatedRoutes.includes(pathname);
};

export default Router;
