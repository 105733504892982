import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { IAlgorithm } from "../../models/algorithm";

import Loader from "../../components/Loader";

import { useRehoboamApi } from "../../hooks/useRehoboamApi";

const AlgorithmListPage: React.FC = () => {
  const { listAlgorithms, isSignedIn } = useRehoboamApi();
  const [state, setState] = useState<{ statusCode?: number; algorithms: IAlgorithm[] }>({
    statusCode: undefined,
    algorithms: [],
  });

  useEffect(() => {
    if (!isSignedIn) return;

    listAlgorithms().then((response) => {
      const statusCode = response.statusCode;
      const algorithms = statusCode === 200 ? response.data : [];
      setState((prevState) => ({ ...prevState, statusCode, algorithms }));
    });
  }, [isSignedIn, listAlgorithms]);

  if (!state.statusCode) {
    return (
      <div className="container-fluid">
        <Header />
        <Loader />
      </div>
    );
  }

  if (state.statusCode !== 200) {
    return (
      <div className="container-fluid">
        <Header />
        <ApiError />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <Header />
      <AlgorithmList algorithms={state.algorithms} />
    </div>
  );
};

const AlgorithmList: React.FC<{ algorithms: IAlgorithm[] }> = ({ algorithms }) => {
  return (
    <>
      {algorithms.map((algorithm) => (
        <div key={algorithm.id} className="card">
          <div className="clearfix">
            <div className="float-left d-inline-block">
              <h2 className="card-title mr-10">{algorithm.name}</h2>
            </div>
            <div className="float-right d-inline-block">
              <span className="badge">{algorithm.id}</span>
            </div>
          </div>

          <div className="text mb-10">{algorithm.description}</div>
          <Link to={`/algorithms/${algorithm.id}`}>
            <button className="btn btn-primary" type="button">
              <span className="text">{"Dashboard"}</span>
            </button>
          </Link>
        </div>
      ))}
    </>
  );
};

const Header: React.FC = () => {
  return (
    <div className="content">
      <h1 className="content-title font-size-22">Algorithms</h1>
      <p>Your list of algorithms.</p>
    </div>
  );
};

const ApiError: React.FC = () => {
  return (
    <div className="card alert alert-secondary filled-dm" role="alert">
      <h4 className="alert-heading">No Algorithms Found</h4>
      We couldn't find any algorithms and creating them is not supported yet.
    </div>
  );
};

export default AlgorithmListPage;
