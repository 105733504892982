import * as signalR from "@microsoft/signalr";

// const endpoint = "https://localhost:5001";
const endpoint = "https://api.rehoboam-app.com";

let authToken = "";

export function setAuthToken(apiAuthToken: string) {
  authToken = apiAuthToken;
}

export async function listAlgorithms(): Promise<ApiResponse> {
  try {
    const response = await fetch(`${endpoint}/api/algorithms`, {
      headers: {
        Authorization: authToken,
      },
    });
    console.log(response);

    return {
      statusCode: response.status,
      data: await response.json(),
    };
  } catch (err) {
    console.error(err);
    return failedToCommunicate;
  }
}

export async function describeAlgorithm(algorithmId: string) {
  try {
    const response = await fetch(`${endpoint}/api/algorithms/${algorithmId}`, {
      headers: {
        Authorization: authToken,
      },
    });
    console.log(response);

    return {
      statusCode: response.status,
      data: await response.json(),
    };
  } catch (err) {
    console.error(err);
    return failedToCommunicate;
  }
}

export async function listAlgorithmInstances(algorithmId: string) {
  try {
    const response = await fetch(`${endpoint}/api/algorithms/${algorithmId}/instances`, {
      headers: {
        Authorization: authToken,
      },
    });
    console.log(response);

    return {
      statusCode: response.status,
      data: await response.json(),
    };
  } catch (err) {
    console.error(err);
    return failedToCommunicate;
  }
}

export async function describeAlgorithmInstance(algorithmId: string, instanceId: string) {
  try {
    const response = await fetch(`${endpoint}/api/algorithms/${algorithmId}/instances/${instanceId}`, {
      headers: {
        Authorization: authToken,
      },
    });
    console.log(response);

    return {
      statusCode: response.status,
      data: await response.json(),
    };
  } catch (err) {
    console.error(err);
    return failedToCommunicate;
  }
}

export async function createInstance(algorithmId: string) {
  try {
    const response = await fetch(`${endpoint}/api/algorithms/${algorithmId}/instances`, {
      method: "post",
      headers: {
        Authorization: authToken,
      },
    });
    console.log(response);

    return {
      statusCode: response.status,
      data: await response.json(),
    };
  } catch (err) {
    console.error(err);
    return failedToCommunicate;
  }
}

export async function stopInstance(algorithmId: string, instanceId: string) {
  try {
    const response = await fetch(`${endpoint}/api/algorithms/${algorithmId}/instances/${instanceId}`, {
      method: "put",
      headers: {
        Authorization: authToken,
      },
    });
    console.log(response);

    return {
      statusCode: response.status,
      data: await response.json(),
    };
  } catch (err) {
    console.error(err);
    return failedToCommunicate;
  }
}

export async function stopAllInstances(algorithmId: string) {
  try {
    const response = await fetch(`${endpoint}/api/algorithms/${algorithmId}/instances`, {
      method: "put",
      headers: {
        Authorization: authToken,
      },
    });
    console.log(response);

    return {
      statusCode: response.status,
      data: await response.json(),
    };
  } catch (err) {
    console.error(err);
    return failedToCommunicate;
  }
}

export async function deleteInstance(algorithmId: string, instanceId: string) {
  try {
    const response = await fetch(`${endpoint}/api/algorithms/${algorithmId}/instances/${instanceId}`, {
      method: "delete",
      headers: {
        Authorization: authToken,
      },
    });
    console.log(response);

    return {
      statusCode: response.status,
      data: await response.json(),
    };
  } catch (err) {
    console.error(err);
    return failedToCommunicate;
  }
}

export async function getTopMentions(date: string) {
  try {
    const response = await fetch(`${endpoint}/api/alternative-data/reddit/wallstreetbets?date=${date}`, {
      headers: {
        Authorization: authToken,
      },
    });
    console.log(response);

    return {
      statusCode: response.status,
      data: await response.json(),
    };
  } catch (err) {
    console.error(err);
    return failedToCommunicate;
  }
}

export async function describeSymbol(symbol: string) {
  try {
    const response = await fetch(`${endpoint}/api/alternative-data/reddit/wallstreetbets/${symbol}`, {
      headers: {
        Authorization: authToken,
      },
    });
    console.log(response);

    return {
      statusCode: response.status,
      data: await response.json(),
    };
  } catch (err) {
    console.error(err);
    return failedToCommunicate;
  }
}

export const createDataConnection = (authToken: string): signalR.HubConnection => {
  const dataConnection = new signalR.HubConnectionBuilder()
    .withUrl(`${endpoint}/data`, {
      accessTokenFactory: () => authToken,
    })
    .configureLogging(signalR.LogLevel.Error)
    .withAutomaticReconnect()
    .build();

  dataConnection.start().catch((error) => {
    console.log(error);
  });

  return dataConnection;
};

interface ApiResponse {
  statusCode: number;
  data: any;
}

const failedToCommunicate: ApiResponse = {
  statusCode: 500,
  data: "Failed communicating with server.",
};
