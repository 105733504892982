import React from "react";
import { NavLink, Redirect } from "react-router-dom";
import { useGoogleAuth } from "../../hooks/useGoogleAuth";

const LoginPage: React.FC = () => {
  const { isSignedIn } = useGoogleAuth();
  const redirect = () => <Redirect to="/algorithms" />;

  if (isSignedIn) {
    return redirect();
  }

  return (
    <div className="page-wrapper with-navbar">
      <NavBar />
      <div className="content-wrapper d-lg-flex align-items-lg-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <SignInCard redirect={redirect} />
              <div className="content">
                <hr />
                <p>Rehoboam uses your email to identify your account and save resources you create.</p>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NavBar: React.FC = () => {
  return (
    <nav className="navbar" style={{ backgroundColor: "black" }}>
      <NavLink to="/" className="navbar-brand ml-10 ml-sm-15">
        <span className="mr-5 mr-sm-10" style={{ fontSize: 22 }}>
          <i style={{ color: "#0be881" }} className="far fa-circle" />
          {" Rehoboam"}
        </span>
      </NavLink>
      <span className="badge font-size-12 text-monospace px-5 px-sm-10">alpha</span>
    </nav>
  );
};

const SignInCard: React.FC<{ redirect: () => void }> = ({ redirect }) => {
  return (
    <div className="card text-center">
      <h2 className="card-title mr-10">{"Sign in to continue"}</h2>
      <GoogleButton redirect={redirect} />
      <br />
      <TwitterButton />
      <br />
      <AppleButton />
    </div>
  );
};

const GoogleButton: React.FC<{ redirect: () => void }> = ({ redirect }) => {
  const { signIn } = useGoogleAuth();

  return (
    <button
      className="btn btn-block btn-lg"
      type="button"
      onClick={() => {
        signIn().then(() => {
          redirect();
        });
      }}
    >
      <span>
        <img
          width="20"
          height="20"
          alt="google"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
        />
        <span className="ml-10">{" Sign in with Google"}</span>
      </span>
    </button>
  );
};

const TwitterButton: React.FC = () => {
  return (
    <button className="btn btn-block btn-lg disabled" type="button">
      <span>
        <img
          width="23"
          height="20"
          alt="twitter"
          src="https://upload.wikimedia.org/wikipedia/sco/thumb/9/9f/Twitter_bird_logo_2012.svg/172px-Twitter_bird_logo_2012.svg.png"
        />
        <span className="ml-10">{" Sign in with Twitter"}</span>
      </span>
    </button>
  );
};

const AppleButton: React.FC = () => {
  return (
    <button className="btn btn-block btn-lg disabled" type="button">
      <span>
        <img
          width="20"
          height="22"
          alt="apple"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/505px-Apple_logo_black.svg.png"
        />
        <span className="ml-10">{" Sign in with Apple"}</span>
      </span>
    </button>
  );
};

export default LoginPage;
